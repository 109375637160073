import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import ProjetBody from "./ProjetBody"

const HeaderProjet = ({ titre, illustration, slug, color, description }) => {
  return (
    <section className="page-projet">
      <div className="entete-projet max-w-[1920px] mx-auto">
        <div className="entete-bg bg-visuall-bg" />
        <div className="presentation">
          <h1 className="text-8xl text-visuall-bleu-logo font-bold font-archia -mr-24">
            {titre}
          </h1>
          <div className="pr-12">
            <ProjetBody content={description} />
          </div>
        </div>
        <div className="media-illustration">
          <GatsbyImage image={illustration} alt={`illustration ${titre}`} />
        </div>
      </div>
    </section>
  )
}

HeaderProjet.propTypes = {
  titre: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
  color: PropTypes.string,
}

export default HeaderProjet
