import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import HeaderProjet from "../../components/PageProjet/Header"
import DynamicZone from "../../components/DynamicZone"

const DatoCmsProjetPageSlug = ({ data }) => {
  const PROJET = data.datoCmsProjetPage
  return (
    <Layout>
      <Seo title={PROJET.titre} />
      <HeaderProjet
        titre={PROJET.titre}
        sousTitre={PROJET.sousTitre}
        slug={PROJET.slug}
        illustration={PROJET.illustration.gatsbyImageData}
        description={PROJET.description}
      />
      {PROJET.contenu && <DynamicZone content={PROJET.contenu} />}
    </Layout>
  )
}

DatoCmsProjetPageSlug.propTypes = {}

export const pageQuery = graphql`
  query ProjetPageSlug($slug: String!) {
    datoCmsProjetPage(slug: { eq: $slug }) {
      titre
      sousTitre
      locale
      slug
      illustration {
        gatsbyImageData
      }
      description {
        __typename
        value
      }
      contenu {
        __typename
        ... on DatoCmsSpotlightProjet {
          id
          titre
          illustration {
            gatsbyImageData
          }
          description
          category {
            color {
              hex
              rgb
            }
            slug
            title
          }
        }

        ... on DatoCmsImageSBlock {
          imageS {
            image {
              gatsbyImageData
            }
          }
        }
        ... on DatoCmsVideoBlock {
          videos {
            id
            urlYoutube {
              url
              provider
              height
              providerUid
              thumbnailUrl
              title
              width
            }
            titreDeLaVidO
          }
          titre
          description
          videoDAbord
        }
      }
    }
  }
`

export default DatoCmsProjetPageSlug
